import Routes from '../routes'
import * as searchFields from './searchFields'
import {
  listSearchPageType,
  tourUrls,
  interactiveFlooplanUrls,
  onlineAuctionUrls,
} from './variables'
import { themeColours } from './variables'
import { createFavourite } from './api-services/authService'
import { toast } from 'react-toastify'

export function UrlBuilder(url, options) {
  if (!options) {
    return url
  }

  Object.keys(options).map((key) => {
    if (options[key] === null || options[key] === '') {
      delete options[key]
    }
  })
  let esc = encodeURIComponent
  let query = Object.keys(options)
    .map((k) => esc(k) + '=' + esc(options[k]))
    .join('&')
  url += '?' + query
  return url
}

export function getBeforeYouBidBPIURL(data) {
  const address_string = `${data.address_streetNumber} ${data.address_street} ${data.address_suburb}, ${data.address_state}, Australia`
  let enc_address = encodeURIComponent(address_string)
  let enc_agent_name = encodeURIComponent(data.agents[0].title)
  let enc_agent_phone = data.agents[0].phone
    ? encodeURIComponent(data.agents[0].phone)
    : encodeURIComponent(data.agents[0].office_phone)
  let enc_agent_email = encodeURIComponent(data.agents[0].email)
  let before_you_bid_url =
    `https://beforeyoubid.com.au/address-map-action/` +
    enc_address +
    '/' +
    data.office.before_you_bid_bpi +
    '/' +
    enc_agent_name +
    ',' +
    enc_agent_phone +
    ',' +
    enc_agent_email
  return before_you_bid_url
}

export function getSelectizeMultiSelectionValues(event) {
  var result = []
  var options = event && event.options
  var opt
  if (!options) return ''

  for (var i = 0, iLen = options.length; i < iLen; i++) {
    opt = options[i]
    result.push(opt.value || opt.text)
  }
  return result
}

export function stripQueryParam(query, param) {
  // Delete keys from a next.js query object
  // We want an object of the non-slug query to pass queryParams to the backend
  const queryParams = Object.keys(query).reduce((object, key) => {
    if (key !== param) {
      object[key] = query[key]
    }
    return object
  }, {})
  return queryParams
}

export function titleCase(str) {
  if (!str) return ''
  str = str.toLowerCase().split(' ')
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
  }
  return str.join(' ')
}

export function isFloat(n) {
  return n === +n && n !== (n | 0)
}

export function numberFormat(value) {
  if (!value) return null

  return new Intl.NumberFormat('en-au').format(value)
}

export function GooglePlacesToAppraisalAddress(location) {
  let result = {}
  if (location) {
    result.address_full_text = location.formatted_address
    if (location.address_components) {
      location.address_components.map((item) => {
        switch (item.types[0]) {
          case 'subpremise': {
            result.address_sub_number = item.long_name
            if (item.long_name.includes('Unit')) {
              result.address_unit_no = item.long_name.split('Unit ')[1]
            }
            break
          }
          case 'street_number': {
            result.address_street_number = item.long_name
            result.address_street_no = item.long_name
            break
          }
          case 'route': {
            result.address_street_name = item.long_name
            break
          }
          case 'locality': {
            result.address_suburb = item.long_name
            break
          }
          case 'administrative_area_level_1': {
            result.address_state = item.long_name
            result.address_state_short = item.short_name
            break
          }
          case 'postal_code': {
            result.address_postcode = item.long_name
            break
          }
        }
      })
    }
  }
  return result
}

export const googleLocationLink = (location) => {
  if (location && location.latitude && location.longitude) {
    return `https://maps.google.com/?q=${location.latitude},${location.longitude}`
  } else {
    return null
  }
}
export function googleDirectionLocation(location) {
  return `https://www.google.com/maps/dir/?api=1&destination=${location.lat},${location.lng}`
}

export function googleDirectionAddress(address) {
  return `https://www.google.com/maps/dir/?api=1&destination=${address}`
}

export function printPage(e) {
  e.preventDefault()
  window.print()
}

// newlineBr()
// --------------
// convert new lines in a string to br tags
export const newlineBr = (string) => {
  let text = string.split('\n').map((line, i) => (
    <>
      {line}
      <br />
    </>
  ))
  return text
}

export function availableDate(date) {
  if (!date) return 'Now'
  const today = new Date()
  const availableDate = new Date(date)

  let dateText = ''
  if (today.setHours(0, 0, 0, 0) >= availableDate.setHours(0, 0, 0, 0)) {
    dateText = 'Now'
  } else {
    dateText = availableDate
  }

  return dateText
}

export function truncateText(text, length, suffix) {
  if (!text || text.length <= length) {
    return text
  }

  return text.substr(0, length) + suffix
}

export const propertyDataToLdJson = (propertyData, url) => {
  const streetAddress = propertyData.address_street_display
    ? propertyData.address_street_display.trim()
    : ''

  let ldJson = [
    {
      '@context': 'http://schema.org',
      '@type': 'Residence',
      address: {
        '@type': 'PostalAddress',
        addressLocality: propertyData.address_suburb,
        suburb: propertyData.address_suburb,
        addressRegion: propertyData.address_state,
        postalCode: propertyData.address_postcode,
        postcode: propertyData.address_postcode,
        streetAddress: streetAddress,
      },
      name: streetAddress,
    },
  ]
  if (propertyData.status != 'sold') {
    if (propertyData.inspections && propertyData.inspections.length > 0) {
      propertyData.inspections.map((inspection) => {
        ldJson.push({
          '@context': 'http://schema.org',
          '@type': 'Event',
          location: {
            '@context': 'http://schema.org',
            '@type': 'Residence',
            address: {
              '@type': 'PostalAddress',
              addressLocality: propertyData.address_suburb,
              suburb: propertyData.address_suburb,
              addressRegion: propertyData.address_state,
              postalCode: propertyData.address_postcode,
              postcode: propertyData.address_postcode,
              streetAddress: streetAddress,
            },
            name: streetAddress,
          },
          name: 'Inspection',
          startDate: inspection.inspection_time,
          endDate: inspection.inspection_end_time,
          url: url || '',
        })
      })
    }
    if (propertyData.auction_date) {
      ldJson.push({
        '@context': 'http://schema.org',
        '@type': 'Event',
        location: {
          '@context': 'http://schema.org',
          '@type': 'Residence',
          address: {
            '@type': 'PostalAddress',
            addressLocality: propertyData.address_suburb,
            suburb: propertyData.address_suburb,
            addressRegion: propertyData.address_state,
            postalCode: propertyData.address_postcode,
            postcode: propertyData.address_postcode,
            streetAddress: streetAddress,
          },
          name: streetAddress,
        },
        name: 'Auction',
        startDate: propertyData.auction_date,
        endDate: '',
        url: url || '',
      })
    }
  }

  return ldJson
}

export const getPropertyTypes = (propertyClass = false) => {
  if (!propertyClass) return null

  const list = searchFields.propertyType.options.filter((op) => {
    delete op.$order

    if (!op.Classification) return true

    const Classifications = op.Classification.split(';')
    if (Classifications.includes(propertyClass)) return true

    return false
  })

  return list
}

export const propertyChannelString = (propertyData) => {
  if (propertyData.status == 'sold') return 'Sold'
  if (propertyData.listing_type == 'lease') return 'For Rent'
  if (propertyData.listing_type == 'sale') return 'For Sale'
  return ''
}

export const propertyDetailsUrl = (propertyData) => {
  const LISTING_URL_SOLD = 'sold-properties'
  const LISTING_URL_SALE = 'for-sale'
  const LISTING_URL_RENT = 'rental-properties'
  const LISTING_URL_DEFAULT = LISTING_URL_SALE
  let type = LISTING_URL_DEFAULT
  if (propertyData.status === 'sold') {
    type = LISTING_URL_SOLD
  } else if (propertyData.listing_type === 'lease') {
    type = LISTING_URL_RENT
  } else if (propertyData.listing_type === 'sale') {
    type = LISTING_URL_SALE
  }

  const routes = Routes.findAndGetUrls('propertyDetails', {
    type: type,
    slug: propertyData.slug,
    id: propertyData.id,
  })
  return routes && routes.urls && routes.urls.as
}

export const stripTags = (htmlString) => {
  if (htmlString) {
    return htmlString.replace(/<[^>]*>?/gm, '')
  }
  return ''
}

export const currencyFormat = (value, withK = false) => {
  const options = {
    style: 'currency',
    currency: 'AUD',
    maximumFractionDigits: 0,
  }

  if (withK) {
    options.notation = 'compact'
  }

  return new Intl.NumberFormat('en-au', options).format(value)
}

// filterHrefs()
// -----------------
// filter hrefs into usable categories
export const filterHrefs = (
  property = null,
  categories = 'videos,tours,external,interactivefloorplans,onlineauctions'
) => {
  if (!property || !property.hrefs || !property.hrefs.length) return false

  let videos = []
  let tours = []
  let external = []
  let interactivefloorplans = []
  let onlineauctions = []

  // videos
  videos = property.hrefs
    .filter(
      (href) => href.link_type === 'video' || href.href.indexOf('youtu') > -1
    )
    .map((item) => {
      return {
        href: item.href,
      }
    })

  // virtual tours
  tours = property.hrefs
    .filter(
      (href) =>
        (href.link_type === 'virtual tour' ||
          tourUrls.find((u) => href.href.indexOf(u.url) > -1)) &&
        !(href.link_type === 'video' || href.href.indexOf('youtu') > -1)
    )
    .map((item) => item.href)

  // interactive floorplans
  interactivefloorplans = property.hrefs
    .filter((href) =>
      interactiveFlooplanUrls.find((u) => href.href.indexOf(u.url) > -1)
    )
    .map((item) => item.href)

  // online auctions
  onlineauctions = property.hrefs
    .filter((href) =>
      onlineAuctionUrls.find((u) => href.href.indexOf(u.url) > -1)
    )
    .map((item) => item.href)

  // external links
  external = property.hrefs
    .filter(
      (href) =>
        !(href.link_type === 'video' || href.href.indexOf('youtu') > -1) &&
        !(
          href.link_type === 'virtual tour' || tours.find((u) => u == href.href)
        ) &&
        !interactivefloorplans.find((u) => u == href.href) &&
        !onlineauctions.find((u) => u == href.href)
    )
    .map((item) => item.href)

  let hrefs = {}
  if (categories.indexOf('videos') > -1 && videos.length) hrefs.videos = videos
  if (categories.indexOf('tours') > -1 && tours.length) hrefs.tours = tours
  if (categories.indexOf('external') > -1 && external.length)
    hrefs.external = external
  if (
    categories.indexOf('interactivefloorplans') > -1 &&
    interactivefloorplans.length
  )
    hrefs.interactivefloorplans = interactivefloorplans
  if (categories.indexOf('onlineauctions') > -1 && onlineauctions.length)
    hrefs.onlineauctions = onlineauctions

  return hrefs
}

// form input color setting helper
export const colorSettingParser = (color) => {
  if (color == themeColours.brand) {
    return 'red'
  }
  if (color == themeColours.titles) {
    return 'blue'
  }
  if (color == themeColours.white) {
    return 'white'
  }
}

/*
  ----------------------------------------------------------------------------------------------------
  isNullOrEmpty()
  ----------------------------------------------------------------------------------------------------
  check a variable is null or empty
*/
export const isNullOrEmpty = (input) => {
  // Null or empty
  if (input === null || input === undefined || input === '') {
    return true
  }

  // value = False
  if (input === 'False') {
    return true
  }

  // Array empty
  if (typeof input.length === 'number' && typeof input !== 'function') {
    return !input.length
  }

  // Blank string like '   '
  if (typeof input === 'string' && input.match(/\S/) === null) {
    return true
  }

  // Object empty
  if (input.constructor === Object && Object.keys(input).length === 0) {
    return true
  }

  return false
}

/*
  ----------------------------------------------------------------------------------------------------
  isNewTab()
  ----------------------------------------------------------------------------------------------------
  Check if a link is http/https or pdf - open these in a new tab
*/
export const isNewTab = (link) => {
  if (!link) return false
  let newTab = false
  let isBPURL = link.indexOf('barryplant.com.au') > -1

  // link includes http:// or https://
  if (!isBPURL) {
    if (link.indexOf('http://') > -1 || link.indexOf('https://') > -1)
      newTab = true
  }

  // link includes .pdf
  if (link.indexOf('.pdf') > -1) newTab = true

  return newTab
}

export const isExternalURL = (url) => {
  if (!url) return false
  if (url.includes('http:') || url.includes('https:')) {
    return true
  }
  return false
}

export const cleanSaveSearchParams = (params) => {
  if (params.query) {
    let multiValues = JSON.parse(params.query)
    params.query = ''
    params.address_suburb = ''
    params.address_postcode = ''
    params.address_region = ''
    multiValues &&
      multiValues.map((s) => {
        switch (s.type) {
          case 'postcodes': {
            const prefix = params.address_postcode ? ',' : ''
            params.address_postcode += prefix + s.value
            break
          }
          case 'suburbs': {
            const prefix = params.address_suburb ? ',' : ''
            params.address_suburb += prefix + s.value
            break
          }
          case 'regions': {
            const prefix = params.address_region ? ',' : ''
            params.address_region += prefix + s.value
            break
          }
        }
      })
  }

  return { ...params }
}

export const isSearchSaved = (params, alerts = [], isAlert) => {
  let nParams = JSON.parse(JSON.stringify(params))
  delete nParams.order_by
  delete nParams.advanced
  delete nParams.save_search
  if (!nParams.slug) nParams.slug = ''
  if (!nParams.keywords) nParams.keywords = ''

  const sortObj = (obj) => {
    return Object.keys(obj)
      .sort()
      .reduce(function (acc, key) {
        acc[key] = obj[key]
        return acc
      }, {})
  }
  nParams = sortObj(cleanSaveSearchParams(nParams))
  const paramsString = JSON.stringify(nParams)

  const saveSearchParams = alerts.map((item) => {
    delete item.criteria.order_by
    delete item.criteria.advanced
    delete item.criteria.save_search
    if (!item.criteria.keywords) item.criteria.keywords = ''
    return JSON.stringify(sortObj(item.criteria))
  })

  if (saveSearchParams.indexOf(paramsString) > -1) {
    return isAlert
      ? alerts[saveSearchParams.indexOf(paramsString)]?.frequency > 0
      : true
  }
  return false
}

export function getAlignmentClass(alignment) {
  let result = ''
  switch (alignment) {
    case 'L': {
      result = 'text-left'
      break
    }
    case 'C': {
      result = 'text-center'
      break
    }
    case 'R': {
      result = 'text-right'
      break
    }
  }
  return result
}

export const formatPrice = (price, propertyType) => {
  if (price && price.includes('$')) {
    const value = price ? price.slice(price.length - 1, price.length) : ''
    let isMillion = false
    let isThousand = false
    if (value == 'm' || value == 'M') {
      isMillion = true
    } else if (value == 'k' || value == 'K') {
      isThousand = true
    }
    const removeTo = price.replace('to', '-')
    const regex =
      propertyType == 'for lease' || propertyType == 'SOLD'
        ? /[^\d.]/g
        : /[^0-9_-]/g
    const removeAllcharacter = removeTo.replace(regex, '')
    const removeAllWhiteSpace = removeAllcharacter.replace(/\s/g, '')
    const prices = removeAllWhiteSpace.split('-')

    return {
      prices: prices,
      isMillion: isMillion,
      isThousand: isThousand,
    }
  }

  return {}
}

export const getUpperPrice = (listing, showType) => {
  const propertyType = getOutCome(listing, showType)
  const price =
    propertyType == 'SOLD'
      ? listing.soldDetails_price.toString()
      : listing.priceGuide
  return handleGetPrice(price, 1, propertyType, listing)
}

export const getLowerPrice = (listing, showType) => {
  const propertyType = getOutCome(listing, showType)
  const price =
    propertyType == 'SOLD'
      ? listing.soldDetails_price.toString()
      : listing.priceGuide
  return handleGetPrice(price, 0, propertyType, listing)
}

export const handleGetPrice = (price, index, propertyType, listing) => {
  const data = formatPrice(price, propertyType)
  if (propertyType == 'for lease') {
    return data && data.prices && !!data.prices.length && data.prices[index]
      ? numberWithCommas(+data.prices[index])
      : ''
  } else if (propertyType == 'leased') {
    return ''
  } else if (propertyType == 'SOLD') {
    return listing && listing.soldDetails_price_display
      ? numberWithCommas(price)
      : ''
  } else {
    if (data.isMillion) {
      const million = parseInt(data.prices[index]) + '00'
      return numberWithCommas(million.slice(0, 4) + '000')
    } else if (data.isThousand) {
      return numberWithCommas(data.prices[index] + '000')
    } else {
      return data && data.prices && !!data.prices.length && data.prices[index]
        ? numberWithCommas(data.prices[index].slice(0, -3) + '000')
        : ''
    }
  }
}

export const numberWithCommas = (x) => {
  var numberToString = x.toString()
  var pattern = /(-?\d+)(\d{3})/
  while (pattern.test(numberToString))
    numberToString = numberToString.replace(pattern, '$1,$2')
  return numberToString
}

export const getOutCome = (dataSource, showType) => {
  if (dataSource.status != 'sold' && dataSource.underOffer) {
    return 'Under offer'
  }

  if (showType) {
    switch (showType) {
      case listSearchPageType.SEARCH_TYPE_AUCTION: {
        if (dataSource.auction_date) {
          return `${dataSource.onlineauctions ? 'Online ' : ''}Auction`
        }
        break
      }
      case listSearchPageType.SEARCH_TYPE_INSPECTION: {
        break
      }
      case listSearchPageType.SEARCH_TYPE_SALEDATE: {
        if (dataSource.salebySetDate) {
          return `Sale by SET DATE`
        }
        break
      }
      default: {
        break
      }
    }
  }

  if (dataSource.status == 'sold') {
    return 'SOLD'
  }

  if (dataSource.status == 'leased') {
    return 'leased'
  }

  if (dataSource.auction_date) {
    return `${dataSource.onlineauctions ? 'Online ' : ''}Auction`
  }

  if (dataSource.listingType == 'sale' && dataSource.salebySetDate) {
    return `Sale by SET DATE`
  }

  if (dataSource.listingType == 'sale') {
    return 'For sale'
  }

  if (
    dataSource.listingType == 'lease' &&
    dataSource.dateAvailable &&
    dataSource.status != 'leased'
  ) {
    return 'for lease'
  }

  if (dataSource.listingType == 'lease') {
    return 'for lease'
  }

  return ''
}

export const getPropertyType = (dataSource, showType) => {
  const propertyType = getOutCome(dataSource, showType)
  if (propertyType == 'SOLD') {
    return 'sold'
  } else if (propertyType == 'for lease') {
    return 'for lease'
  } else if (propertyType == 'leased') {
    return 'lease'
  } else {
    return 'for sale'
  }
}

export const createFavouriteFromLocalstore = async (token) => {
  let favouriteParams = localStorage.getItem('favouriteParams')
  if (favouriteParams) {
    favouriteParams = JSON.parse(favouriteParams)
    const fResponse = await createFavourite(
      token,
      favouriteParams.type,
      favouriteParams.id
    )
    if (!fResponse.error) {
      localStorage.removeItem('favouriteParams')
      toast.success('Your item has been saved')
    }
  }
}

export const isIE = function (range) {
  const comparator = {
    '<': function (a, b) {
      return a < b
    },
    '<=': function (a, b) {
      return a <= b
    },
    '>': function (a, b) {
      return a > b
    },
    '>=': function (a, b) {
      return a >= b
    },
  }

  function compareVersion(version, range) {
    var string = range + ''
    var n = +(string.match(/\d+/) || NaN)
    var op = string.match(/^[<>]=?|/)[0]
    return comparator[op] ? comparator[op](version, n) : version == n || n !== n
  }

  const userAgent = ((navigator && navigator.userAgent) || '').toLowerCase()
  const match = userAgent.match(/(?:msie |trident.+?; rv:)(\d+)/)
  return match !== null && compareVersion(match[1], range)
}

export const getCTALink = (item, defaultText = 'View More') => {
  const link =
    item?.call_to_action_external_link || item?.call_to_action_link?.path
  const linkText =
    item?.call_to_action_text || item?.call_to_action_link?.title || defaultText

  if (link) {
    return { href: link, label: linkText }
  }

  return null
}

const extractVideoId = (url) => {
  const match = url.match(
    /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=))([a-zA-Z0-9_-]{11})/
  )
  return match ? match[1] : null
}

export const getYoutubeThumbnail = (url) => {
  // Extract video ID
  const videoId = extractVideoId(url)

  if (!videoId) return null

  // Create thumbnail URL
  return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
}
